<script setup lang="ts">
import type { NuxtError } from '#app'
import { UndoIcon } from 'lucide-vue-next'

const props = defineProps({
  error: Object as () => NuxtError,
})
</script>

<template>
  <div class="flex h-screen flex-col items-center justify-center">
    <h1 class="text-5xl font-bold">500</h1>
    <p class="mt-2 text-2xl">Internal Server Error</p>

    <Button asChild class="mt-4">
      <NuxtLink href="/">
        <UndoIcon class="mr-2 size-4" /> Go to homepage
      </NuxtLink>
    </Button>

    <p class="mt-2">
      {{ JSON.stringify(props.error) }}
    </p>
  </div>
</template>
