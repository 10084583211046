import revive_payload_client_MBuSRR063I from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UIM408YLkk from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZsTVunY5en from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_DHMTOSd44m from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sGmulw5a9B from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XsAcHlHgHW from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_q9eya0hHS1 from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/zougengyu/Projects/chat-pet/.nuxt/components.plugin.mjs";
import prefetch_client_iEB2TA4lpi from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_j5nxg73t7k4evgzx656ypnkdua/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_g3202afUA8 from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_RnkGRZ9NfI from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_VPWQT1XG09 from "/Users/zougengyu/Projects/chat-pet/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_MBuSRR063I,
  unhead_UIM408YLkk,
  router_ZsTVunY5en,
  payload_client_DHMTOSd44m,
  navigation_repaint_client_sGmulw5a9B,
  check_outdated_build_client_XsAcHlHgHW,
  chunk_reload_client_q9eya0hHS1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iEB2TA4lpi,
  plugin_client_g3202afUA8,
  switch_locale_path_ssr_RnkGRZ9NfI,
  i18n_VPWQT1XG09
]