import { default as billing7RoEAHcXNHMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/account/billing.vue?macro=true";
import { default as indexcRGPzhCZ8FMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/account/index.vue?macro=true";
import { default as profile9pHObIQamUMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/account/profile.vue?macro=true";
import { default as forgot_45password3IdP8lMRdtMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/auth/forgot-password.vue?macro=true";
import { default as loginnRfzm2202fMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/auth/login.vue?macro=true";
import { default as otpezS4a6trXaMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/auth/otp.vue?macro=true";
import { default as signupNagIt9LP43Meta } from "/Users/zougengyu/Projects/chat-pet/app/pages/auth/signup.vue?macro=true";
import { default as verifypaGdovCGEHMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/auth/verify.vue?macro=true";
import { default as _91path_93rBGctQo6oLMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/blog/[path].vue?macro=true";
import { default as indexcJqgFnmeCxMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/blog/index.vue?macro=true";
import { default as indexBFaxx7FkNlMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/chat/index.vue?macro=true";
import { default as _91_46_46_46path_937VrwDqCWaPMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/docs/[...path].vue?macro=true";
import { default as indexnUJhNnn0VxMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/index.vue?macro=true";
import { default as _91path_93RPcZ0QmI5aMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/legal/[path].vue?macro=true";
import { default as onboardingfFQQcHAuhqMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/onboarding.vue?macro=true";
import { default as pricingKkKnG75o3EMeta } from "/Users/zougengyu/Projects/chat-pet/app/pages/pricing.vue?macro=true";
export default [
  {
    name: "account-billing",
    path: "/account/billing",
    meta: billing7RoEAHcXNHMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profile9pHObIQamUMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45password3IdP8lMRdtMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginnRfzm2202fMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-otp",
    path: "/auth/otp",
    meta: otpezS4a6trXaMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/auth/otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupNagIt9LP43Meta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify",
    path: "/auth/verify",
    meta: verifypaGdovCGEHMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: "blog-path",
    path: "/blog/:path()",
    meta: _91path_93rBGctQo6oLMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/blog/[path].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexcJqgFnmeCxMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    meta: indexBFaxx7FkNlMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-path",
    path: "/docs/:path(.*)*",
    meta: _91_46_46_46path_937VrwDqCWaPMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/docs/[...path].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexnUJhNnn0VxMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-path",
    path: "/legal/:path()",
    meta: _91path_93RPcZ0QmI5aMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/legal/[path].vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingfFQQcHAuhqMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingKkKnG75o3EMeta || {},
    component: () => import("/Users/zougengyu/Projects/chat-pet/app/pages/pricing.vue").then(m => m.default || m)
  }
]